// core components
import Dashboard from "views/Dashboard.jsx";
import Icons from "views/Icons.jsx";
import Profile from "views/Profile.jsx";
import Tables from "views/Tables.jsx";
// @material-ui/icons components
import FormatListBulleted from "@material-ui/icons/FormatListBulleted";
import Grain from "@material-ui/icons/Grain";
import Person from "@material-ui/icons/Person";
import Tv from "@material-ui/icons/Tv";

var routes = [
  {
    divider: true,
  },
  {
    path: "/index",
    name: "Панель управления",
    icon: Tv,
    iconColor: "Primary",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/icons",
    name: "Иконки",
    icon: Grain,
    iconColor: "Primary",
    component: Icons,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "Профиль",
    icon: Person,
    iconColor: "WarningLight",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/tables",
    name: "Таблицы",
    icon: FormatListBulleted,
    iconColor: "Error",
    component: Tables,
    layout: "/admin",
  }
];
export default routes;
