const componentStyles = (theme) => ({
    listGroupItem: {
        position: "relative",
        display: "block",
        marginBottom: "-1px",
        padding: "1rem 1rem",
        border: "1px solid #e9ecef",
        backgroundColor: "#fff"
    },
    listGroup: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "0", 
        paddingLeft: "0"
    },
    card: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        minWidth: "0",
        wordWrap: "break-word",
        borderRadius: ".375rem",
        backgroundColor: "#fff",
        backgroundClip: "border-box",
        boxShadow: "0 0 2rem 0 rgba(136, 152, 170,.15) !important",
        "& > hr": {
            marginRight: "0",
            marginLeft: "0"
        },
        "& > $listGroup:first-child $listGroupItem:first-child": {
            borderTopLeftRadius: ".375rem",
            borderTopRightRadius: ".375rem"
        },
        "& > $listGroup:last-child $listGroupItem:last-child": {
            borderRottomRightRadius: ".375rem",
            borderRottomLeftRadius: ".375rem"
        }
    },
    cardBody: {
        padding: "1.5rem",
        flex: "1 1 auto",
    },
    cardHeader: {
        marginBottom: "0",
        padding: "1.5rem 1.5rem",
        borderBottom: "1px solid rgba(0, 0, 0, .05)",
        backgroundColor: "#fff",
        "&:first-child": {
            borderRadius: "calc(.375rem - 1px) calc(.375rem - 1px) 0 0"
        },
        "& + $listGroup &listGroupItem:first-child": {
            borderTop: "0"
        }
    },
    timeLine: {
        position: "relative",
        "&:before": {
            content: "hello",
            position: "absolute",
            top: "0",
            left: "1rem",
            height: "100%",
            width: "5px",
            backgroundColor: "red",
            borderRight: "2px solid #e9ecef",
            // borderRightStyle: "dashed !important"
        }
    },
    timeLineBlock: {
        position: "relative",
        margin: "2em 0",
        "&:after": {
            display: "table",
            clear: "both",
            content: ''
        },
        "&:first-child": {
            marginTop: "0"
        },
        "&:last-child": {
            marginBottom: "0"
        }
    },
    timeLineStep: {
        fontSize: "1rem",
        fontWeight: "600",
        position: "absolute",
        zIndex: "1",
        left: "0",
        display: "inline-flex",
        width: "33px",
        height: "33px",
        transform: "translateX(-50%)",
        textAlign: "center",
        borderRadius: "50%",
        alignItems: "center",
        justifyContent: "center",
        "& svg, & i": {
            lineHeight: "1.4"
        }
    },
    timeLineContent: {
        position: "relative",
        top: "-6px",
        marginLeft: "60px",
        paddingTop: ".5rem",
        "&:after": {
            display: "table",
            clear: "both",
            content: '',
        }
    },
    timeLineOneSide: {
        "&:defore": {
            left: "1rem"
        },
        "& $timeLineStep": {
            left: "1rem"
        },
        "& $timeLineContent": {
            width: "auto"
        },
        "& $timeLineBlock:nth-child(even) $timeLineContent": {
            float: "none"
        }
    },
    textRight: {
        textAlign: "right !important"
    },
    textMuted: {
        color: "#8898aa !important"
    },
    textSm: {
        fontSize: ".875rem !important"
    },
    fontWeightBold: {
        fontWeight: "600 !important"
    },
    badgePrimary: {
        color: "#2643e9",
        backgroundColor: "#eaecfb",
        "&[href]:hover, &[href]:focus": {
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "#2a44db"
        }
    },
    badgeSecondary: {
        color: "#cfe3f1",
        backgroundColor: "white",
        "&[href]:hover, &[href]:focus": {
            textDecoration: "none",
            color: "#212529",
            backgroundColor: "#cadeeb"
        }
    },
    badgeSuccess: {
        color: "#1aae6f",
        backgroundColor: "#b0eed3",
        "&[href]:hover, &[href]:focus": {
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "#229c68"
        }
    },
    badgeInfo: {
        color: "#03acca",
        backgroundColor: "#aaedf9",
        "&[href]:hover, &[href]:focus": {
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "#0c9cb7"
        }
    },
    badgeLight: {
        color: "#879cb0",
        backgroundColor: "#fdd1da",
        "&[href]:hover, &[href]:focus": {
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "#8b96a2"
        }
    },
    badgeDark: {
        color: "#090c0e",
        backgroundColor: "#6a7783",
        "&[href]:hover, &[href]:focus": {
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "#060607"
        }
    },
    badgeDefault: {
        color: "#091428",
        backgroundColor: "#4172c6",
        "&[href]:hover, &[href]:focus": {
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "#09111e"
        }
    },
    badgeWhite: {
        color: "#e8e3e3",
        backgroundColor: "white",
        "&[href]:hover, &[href]:focus": {
            textDecoration: "none",
            color: "#212529",
            backgroundColor: "#e0e0e0"
        }
    },
    badgeNeutral: {
        color: "#e8e3e3",
        backgroundColor: "white",
        "&[href]:hover, &[href]:focus": {
            textDecoration: "none",
            color: "#212529",
            backgroundColor: "#e0e0e0"
        }
    },
    badgeDarker: {
        color: "black",
        backgroundColor: "#525252",
        "&[href]:hover, &[href]:focus": {
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "black"
        }
    }
});

export default componentStyles;