import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import componentStyles from "../../assets/theme/components/notification";

const useStyles = makeStyles(componentStyles);

export default function NotificationCard() {
    let classes = useStyles()

    return (
        <>
            <Card
                classes={{
                    root: classes.card
                }}
            >
                <CardHeader
                    subheader={
                        <Grid
                            container
                            component={Box}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Grid item xs="auto">
                                <Box
                                    variant="h3"
                                    component={Typography}
                                    marginBottom="0!important"
                                >
                                    Последние уведомления 
                                </Box>
                            </Grid>
                        </Grid>
                    }
                    classes={{ root: classes.cardHeaderRoot }}
                ></CardHeader>
                <CardContent
                    classes={{ root: classes.cardBody }}
                >
                    <Box
                        className={`${classes.timeLine} ${classes.timeLineOneSide}`}
                    >
                        <Box
                            className={classes.timeLineBlock}
                        >
                            <Box
                                component="span"
                                className={`${classes.timeLineStep} ${classes.badgeSuccess}`}
                            >
                                <Box
                                    component="i"
                                    className="ni ni-bell-55"
                                ></Box>
                            </Box>
                            <Box
                                className={classes.timeLineContent}
                            >
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    paddingTop="1"
                                >
                                    <Box
                                        component="span"
                                        className={`${classes.textMuted} ${classes.textSm} ${classes.fontWeightBold}`}
                                    >
                                        Новой сообщение
                                    </Box>
                                    <Box
                                        className={classes.textRight}
                                    >
                                        <Box
                                            component="span"
                                            className={classes.textMuted}
                                            display="flex"
                                            alignItems="center"
                                            gridGap="5"
                                            fontSize="12.5px"
                                        >
                                            <Box
                                                component="i"
                                                className="fas fa-clock"
                                                marginRight=".25rem !important"
                                            ></Box>
                                            2 hrs ago
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    component="h5"
                                    className={classes.textSm}
                                    marginTop="1"
                                    marginBottom="0"
                                >
                                    Let's meet at Starbucks at 11:30. Wdyt?
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            className={classes.timeLineBlock}
                        >
                            <Box
                                component="span"
                                className={`${classes.timeLineStep} ${classes.badgeSuccess}`}
                            >
                                <Box
                                    component="i"
                                    className="ni ni-bell-55"
                                ></Box>
                            </Box>
                            <Box
                                className={classes.timeLineContent}
                            >
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    paddingTop="1"
                                >
                                    <Box
                                        component="span"
                                        className={`${classes.textMuted} ${classes.textSm} ${classes.fontWeightBold}`}
                                    >
                                        Новой сообщение
                                    </Box>
                                    <Box
                                        className={classes.textRight}
                                    >
                                        <Box
                                            component="span"
                                            className={classes.textMuted}
                                            display="flex"
                                            alignItems="center"
                                            gridGap="5"
                                            fontSize="12.5px"
                                        >
                                            <Box
                                                component="i"
                                                className="fas fa-clock"
                                                marginRight=".25rem !important"
                                            ></Box>
                                            2 hrs ago
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    component="h5"
                                    className={classes.textSm}
                                    marginTop="1"
                                    marginBottom="0"
                                >
                                    Let's meet at Starbucks at 11:30. Wdyt?
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
}