import React from "react";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons components
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

// core components
import Header from "components/Headers/Header.jsx";
import NotificationCard from "components/Notification/Notification.jsx"

import {
    chartOptions,
    parseOptions,
    chartExample1,
    chartExample2,
} from "variables/charts.js";

import componentStyles from "assets/theme/views/admin/dashboard.js";

const useStyles = makeStyles(componentStyles);

function Dashboard() {
    const classes = useStyles();
    const theme = useTheme();
    const [activeNav, setActiveNav] = React.useState(1);
    const [chartExample1Data, setChartExample1Data] = React.useState("data1");

    if (window.Chart) {
        parseOptions(Chart, chartOptions());
    }

    const toggleNavs = (index) => {
        setActiveNav(index);
        setChartExample1Data("data" + index);
    };

    return (
        <>
            <Header />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-6rem"
                classes={{ root: classes.containerRoot }}
            >
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        xl={8}
                        component={Box}
                        marginBottom="3rem!important"
                        classes={{ root: classes.gridItemRoot }}
                    >
                        <Card
                            classes={{
                                root: classes.cardRoot + " " + classes.cardRootBgGradient,
                            }}
                        >
                            <CardHeader
                                subheader={
                                    <Grid
                                        container
                                        component={Box}
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Grid item xs="auto">
                                            <Box
                                                component={Typography}
                                                variant="h6"
                                                letterSpacing=".0625rem"
                                                marginBottom=".25rem!important"
                                                className={classes.textUppercase}
                                            >
                                                <Box component="span" color={theme.palette.gray[400]}>
                                                    Overview
                                                </Box>
                                            </Box>
                                            <Box
                                                component={Typography}
                                                variant="h2"
                                                marginBottom="0!important"
                                            >
                                                <Box component="span" color={theme.palette.white.main}>
                                                    Sales value
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Box
                                                justifyContent="flex-end"
                                                display="flex"
                                                flexWrap="wrap"
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    component={Box}
                                                    marginRight="1rem!important"
                                                    onClick={() => toggleNavs(1)}
                                                    classes={{
                                                        root:
                                                            activeNav === 1
                                                                ? ""
                                                                : classes.buttonRootUnselected,
                                                    }}
                                                >
                                                    Month
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => toggleNavs(2)}
                                                    classes={{
                                                        root:
                                                            activeNav === 2
                                                                ? ""
                                                                : classes.buttonRootUnselected,
                                                    }}
                                                >
                                                    Week
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                }
                                classes={{ root: classes.cardHeaderRoot }}
                            ></CardHeader>
                            <CardContent>
                                <Box position="relative" height="350px">
                                    <Line
                                        data={chartExample1[chartExample1Data]}
                                        options={chartExample1.options}
                                        getDatasetAtEvent={(e) => console.log(e)}
                                    />
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} xl={4}>
                        <Card classes={{ root: classes.cardRoot }}>
                            <CardHeader
                                title={
                                    <Box component="span" color={theme.palette.gray[600]}>
                                        Performane
                                    </Box>
                                }
                                subheader="Total orders"
                                classes={{ root: classes.cardHeaderRoot }}
                                titleTypographyProps={{
                                    component: Box,
                                    variant: "h6",
                                    letterSpacing: ".0625rem",
                                    marginBottom: ".25rem!important",
                                    classes: {
                                        root: classes.textUppercase,
                                    },
                                }}
                                subheaderTypographyProps={{
                                    component: Box,
                                    variant: "h2",
                                    marginBottom: "0!important",
                                    color: "initial",
                                }}
                            ></CardHeader>
                            <CardContent>
                                <Box position="relative" height="350px">
                                    <Bar
                                        data={chartExample2.data}
                                        options={chartExample2.options}
                                    />
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container component={Box} marginTop="2rem">
                    {/* <Grid
                        item
                        xs={12}
                        xl={8}
                        component={Box}
                        marginBottom="3rem!important"
                        classes={{ root: classes.gridItemRoot }}
                    >
                        <Card
                            classes={{
                                root: classes.cardRoot,
                            }}
                        >
                            <CardHeader
                                subheader={
                                    <Grid
                                        container
                                        component={Box}
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Grid item xs="auto">
                                            <Box
                                                component={Typography}
                                                variant="h3"
                                                marginBottom="0!important"
                                            >
                                                Page visits
                                            </Box>
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Box
                                                justifyContent="flex-end"
                                                display="flex"
                                                flexWrap="wrap"
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                >
                                                    See all
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                }
                                classes={{ root: classes.cardHeaderRoot }}
                            ></CardHeader>
                            <TableContainer>
                                <Box
                                    component={Table}
                                    alignItems="center"
                                    marginBottom="0!important"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                classes={{
                                                    root:
                                                        classes.tableCellRoot +
                                                        " " +
                                                        classes.tableCellRootHead,
                                                }}
                                            >
                                                Page name
                                            </TableCell>
                                            <TableCell
                                                classes={{
                                                    root:
                                                        classes.tableCellRoot +
                                                        " " +
                                                        classes.tableCellRootHead,
                                                }}
                                            >
                                                Visitors
                                            </TableCell>
                                            <TableCell
                                                classes={{
                                                    root:
                                                        classes.tableCellRoot +
                                                        " " +
                                                        classes.tableCellRootHead,
                                                }}
                                            >
                                                Unique users
                                            </TableCell>
                                            <TableCell
                                                classes={{
                                                    root:
                                                        classes.tableCellRoot +
                                                        " " +
                                                        classes.tableCellRootHead,
                                                }}
                                            >
                                                Bounce rate
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                classes={{
                                                    root:
                                                        classes.tableCellRoot +
                                                        " " +
                                                        classes.tableCellRootBodyHead,
                                                }}
                                                component="th"
                                                variant="head"
                                                scope="row"
                                            >
                                                /argon/
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                4,569
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                340
                                            </TableCell>
                                            <Box
                                                component={TableCell}
                                                className={classes.tableCellRoot}
                                                marginBottom="-2px"
                                            >
                                                <Box
                                                    component={ArrowUpward}
                                                    width="1rem!important"
                                                    height="1rem!important"
                                                    marginRight="1rem"
                                                    color={theme.palette.success.main}
                                                />
                                                46,53%
                                            </Box>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                classes={{
                                                    root:
                                                        classes.tableCellRoot +
                                                        " " +
                                                        classes.tableCellRootBodyHead,
                                                }}
                                                component="th"
                                                variant="head"
                                                scope="row"
                                            >
                                                /argon/index.html
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                3,985
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                319
                                            </TableCell>
                                            <Box
                                                component={TableCell}
                                                className={classes.tableCellRoot}
                                                marginBottom="-2px"
                                            >
                                                <Box
                                                    component={ArrowDownward}
                                                    width="1rem!important"
                                                    height="1rem!important"
                                                    marginRight="1rem"
                                                    color={theme.palette.warning.main}
                                                />
                                                46,53%
                                            </Box>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                classes={{
                                                    root:
                                                        classes.tableCellRoot +
                                                        " " +
                                                        classes.tableCellRootBodyHead,
                                                }}
                                                component="th"
                                                variant="head"
                                                scope="row"
                                            >
                                                /argon/charts.html
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                3,513
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                294
                                            </TableCell>
                                            <Box
                                                component={TableCell}
                                                className={classes.tableCellRoot}
                                                marginBottom="-2px"
                                            >
                                                <Box
                                                    component={ArrowDownward}
                                                    width="1rem!important"
                                                    height="1rem!important"
                                                    marginRight="1rem"
                                                    color={theme.palette.warning.main}
                                                />
                                                36,49%
                                            </Box>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                classes={{
                                                    root:
                                                        classes.tableCellRoot +
                                                        " " +
                                                        classes.tableCellRootBodyHead,
                                                }}
                                                component="th"
                                                variant="head"
                                                scope="row"
                                            >
                                                /argon/tables.html
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                2,050
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                147
                                            </TableCell>
                                            <Box
                                                component={TableCell}
                                                className={classes.tableCellRoot}
                                                marginBottom="-2px"
                                            >
                                                <Box
                                                    component={ArrowUpward}
                                                    width="1rem!important"
                                                    height="1rem!important"
                                                    marginRight="1rem"
                                                    color={theme.palette.success.main}
                                                />
                                                50,87%
                                            </Box>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                classes={{
                                                    root:
                                                        classes.tableCellRoot +
                                                        " " +
                                                        classes.tableCellRootBodyHead +
                                                        " " +
                                                        classes.borderBottomUnset,
                                                }}
                                                component="th"
                                                variant="head"
                                                scope="row"
                                            >
                                                /argon/profile.html
                                            </TableCell>
                                            <TableCell
                                                classes={{
                                                    root:
                                                        classes.tableCellRoot +
                                                        " " +
                                                        classes.borderBottomUnset,
                                                }}
                                            >
                                                1,795
                                            </TableCell>
                                            <TableCell
                                                classes={{
                                                    root:
                                                        classes.tableCellRoot +
                                                        " " +
                                                        classes.borderBottomUnset,
                                                }}
                                            >
                                                190
                                            </TableCell>
                                            <Box
                                                component={TableCell}
                                                className={
                                                    classes.tableCellRoot +
                                                    " " +
                                                    classes.borderBottomUnset
                                                }
                                                marginBottom="-2px"
                                            >
                                                <Box
                                                    component={ArrowDownward}
                                                    width="1rem!important"
                                                    height="1rem!important"
                                                    marginRight="1rem"
                                                    color={theme.palette.error.main}
                                                />
                                                46,53%
                                            </Box>
                                        </TableRow>
                                    </TableBody>
                                </Box>
                            </TableContainer>
                        </Card>
                    </Grid> */}
                    <Grid item xs={12} xl={12}>
                        <Card classes={{ root: classes.cardRoot }}>
                            <CardHeader
                                subheader={
                                    <Grid
                                        container
                                        component={Box}
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Grid item xs="auto">
                                            <Box
                                                component={Typography}
                                                variant="h3"
                                                marginBottom="0!important"
                                            >
                                                Розыгрыши и лотереи
                                            </Box>
                                        </Grid>
                                    </Grid>
                                }
                                classes={{ root: classes.cardHeaderRoot }}
                            ></CardHeader>
                            <TableContainer>
                                <Box
                                    component={Table}
                                    alignItems="center"
                                    marginBottom="0!important"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                classes={{
                                                    root: `${classes.tableCellRoot} ${classes.tableCellRootHead}`
                                                }}
                                            >
                                                Тип
                                            </TableCell>
                                            <TableCell
                                                classes={{
                                                    root: `${classes.tableCellRoot} ${classes.tableCellRootHead}`
                                                }}
                                            >
                                                Описание
                                            </TableCell>
                                            <TableCell
                                                classes={{
                                                    root: `${classes.tableCellRoot} ${classes.tableCellRootHead}`
                                                }}
                                            >
                                                Участники
                                            </TableCell>
                                            <TableCell
                                                classes={{
                                                    root: `${classes.tableCellRoot} ${classes.tableCellRootHead}`
                                                }}
                                            >
                                                Прогресс
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                classes={{
                                                    root: `${classes.tableCellRoot} ${classes.tableCellRootBodyHead}`
                                                }}
                                                component="th"
                                                variant="head"
                                                scope="row"
                                            >
                                                Розыгрышь
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                500 рб коинов
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                45
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                <Box display="flex" alignItems="center">
                                                    <Box component="span" marginRight=".5rem">
                                                        100%
                                                    </Box>
                                                    <Box width="100%">
                                                        <LinearProgress
                                                            variant="determinate"
                                                            value={100}
                                                            classes={{
                                                                root: classes.linearProgressRoot,
                                                                bar: classes.bgGradientSuccess,
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                classes={{
                                                    root:
                                                        classes.tableCellRoot +
                                                        " " +
                                                        classes.tableCellRootBodyHead,
                                                }}
                                                component="th"
                                                variant="head"
                                                scope="row"
                                            >
                                                Розыгрышь
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                1500 рб коинов
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                67
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                <Box display="flex" alignItems="center">
                                                    <Box component="span" marginRight=".5rem">
                                                        63%
                                                    </Box>
                                                    <Box width="100%">
                                                        <LinearProgress
                                                            variant="determinate"
                                                            value={63}
                                                            classes={{
                                                                root: classes.linearProgressRoot,
                                                                bar: classes.bgGradientSuccess,
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Box>
                            </TableContainer>
                        </Card>
                    </Grid>
                </Grid>
                <Grid
                    container
                    marginTop="2rem"
                    component={Box}
                >
                    <Grid item 
                        xs={12}
                        md={12}
                        xl={5}
                        lg={6}
                        sm={12}
                    >
                        <NotificationCard />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default Dashboard;